<template>
	<div>
		<!-- Orders List header -->
		<a-row type="flex" :gutter="24">
			<a-col :span="12" class="mb-24">
				<router-link to="pages/offer/members/list">
					<a-button type="primary">INDICAR</a-button>
				</router-link>
			</a-col>
			<a-col :span="12" class="mb-24 text-right">
				<a-button @click="csvExport(csvData)" class="ml-15">
					<i class="ni ni-archive-2 mr-5"></i> EXPORTAR CSV
				</a-button>
			</a-col>
		</a-row>
		<!-- / Orders List header -->

		<!-- Orders List card -->
		<a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{ padding: 0, paddingTop: '16px' }">

			<!-- Table search -->
			<div class="mx-25">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" class="text-right">
						<a-input-search placeholder="Pesquisar" style="max-width: 200px;" v-model="query"
							@change="onSearchChange" />
					</a-col>
				</a-row>
			</div>
			<!-- / Table search -->

			<!-- Orders table -->
			<a-table class="mt-20" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
				:columns="columns" :data-source="data" :pagination="{ pageSize: pageSize, }" :loading="isLoadingData">

				<!-- <template slot="date" slot-scope="date">
					<p class="mb-0 text-dark">{{ date }}</p>
				</template> -->

				<template slot="Status" slot-scope="Status">
						<a-icon v-if="Status == 'Paid'" type="check-circle" theme="twoTone" two-tone-color="#52c41a" style="font-size: 20px;" />
					<a-button v-else-if="Status == 'Refunded'" shape="circle" size="small"
						class="btn-Status border-muted mr-5">
						<a-icon class="m-0 text-muted" type="undo" style="font-size: 10px;" />
					</a-button>
					<a-button v-else-if="Status == 'Canceled'" shape="circle" size="small"
						class="btn-Status border-danger mr-5">
						<a-icon class="m-0 text-danger" type="close" style="font-size: 10px;" />
					</a-button>
					<span style="vertical-align: middle;">
						<p class="mb-0 text-dark">Indicado</p>
					</span>
				</template>

				<template slot="customer" slot-scope="customer">
					<div class="table-avatar-info">
						<!-- <a-avatar v-if="customer.avatar" shape="circle" :size="24" :src="customer.avatar" /> -->
						<a-avatar shape="circle" :size="24">{{ customer.name.slice(0, 1) }}</a-avatar>
						<div class="avatar-info">
							<p class="mb-0 text-dark">{{ customer.name }}</p>
						</div>
					</div>
				</template>

				<template slot="product" slot-scope="product">
					<p class="mb-0 text-dark">{{ product }}</p>
				</template>

				<template slot="revenue" slot-scope="revenue">
					<p class="mb-0 text-dark">R$ {{ revenue }}</p>
				</template>

				<template slot="editBtn" slot-scope="row">
					<a-button type="link" :data-id="row.key" class="btn-edit">
						Edit
					</a-button>
				</template>

			</a-table>
		</a-card>
	</div>
</template>
  
<script>
// Sorting function for string attributes.
let stringSorter = function (a, b, attr) {
	if (a[attr] < b[attr]) return -1;
	if (a[attr] > b[attr]) return 1;
	return 0;
};

// Table columns
const columns = [
	// {
	// 	title: 'Data de Indicação',
	// 	dataIndex: 'date',
	// 	sorter: (a, b) => a.date.length - b.date.length,
	// 	sortDirections: ['descend', 'ascend'],
	// 	scopedSlots: { customRender: 'date' },

	// },
	{
		title: 'Status',
		dataIndex: 'Status',
		sorter: (a, b) => stringSorter(a, b, 'Status'),
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'Status' },
	},
	{
		title: 'Pessoa indicada',
		dataIndex: 'customer',
		sorter(a, b, attr) {
			if (a.customer.name < b.customer.name) return -1;
			if (a.customer.name > b.customer.name) return 1;
			return 0;
		},
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'customer' },
	},
	{
		title: 'Conteúdo Indicado',
		dataIndex: 'product',
		sorter: (a, b) => stringSorter(a, b, 'product'),
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'product' },
	},
	{
		title: 'Ganhos',
		dataIndex: 'revenue',
		sorter: (a, b) => parseFloat(a.revenue) - parseFloat(b.revenue),
		sortDirections: ['descend', 'ascend'],
		scopedSlots: { customRender: 'revenue' },
	},
];

// Table rows
const data = [];
export default {
	data() {
		return {
			isLoadingData: true,
			drawerVisivel: false,
			// Table columns
			columns,
			// Table rows
			data,
			// Table page size
			pageSize: 10,
			// Table search query
			query: '',
			// Table's selected rows
			selectedRowKeys: [],
			data: [],
			offers: [],
		};
	},
	mounted() {
		this.offers = [];
		this.data = [];
		this.getOffers();
	},
	computed: {
		// CSV data array
		csvData() {
			return this.data.map((item) => ({
				Id: '%23' + item.key,
				'Data de Indicação': item.date,
				Status: item.Status,
				'Pessoa indicada': item.customer.name,
				'Conteúdo Indicado': item.product,
				Revenue: '$' + item.revenue,
			}));
		},
	},
	methods: {
		getOffers() {
			this.$api.dataService
				.getData("member/purchasable")
				.then((res) => {
					if (res.events || res.products || res.courses) {
						this.offers = [
							...this.mapOffers(res.events, "Event"),
							...this.mapOffers(res.products, "Product"),
							...this.mapOffers(res.courses, "Course"),
						];
					}
				})
				.catch((error) => {
					console.error("Erro ao buscar ofertas:", error);
				}).finally(() => {
					this.getIndications();
				})

		},
		mapOffers(offers, type) {
    if (!offers) return [];
    return offers.map((offer) => {
        let mappedType;

        switch (type) {
            case 'Course':
                mappedType = 'Curso';
                break;
            case 'Event':
                mappedType = 'Evento';
                break;
            default:
                mappedType = 'Conteúdo';
        }

        return {
            id: offer.id,
            name: offer.name || offer.title, // Para cursos, usa-se 'title'
            type: mappedType,
        };
    });
},
		getIndications() {

			this.$api.dataService.getData('indication', null, 'all')
				.then((res) => {
					console.log(res);
					if (Array.isArray(res)) {
						const modifiedResponse = res.map(item => {
							const correspondingOffer = this.offers.find(offer => {
								return offer.id == item.association;
							});

							return {

								key: item.id,
								date: '24 Nov de 2023',
								Status: 'Paid',
								customer: {
									name: item.name,
									avatar: 'images/team-2.jpg',
									phone: item.whatsapp
								},
								product: correspondingOffer ? `${correspondingOffer.type} : ${correspondingOffer.name}` : item.association,
								revenue: '-',
							};
						});

						// Adicione os objetos no formato desejado à variável `announcers`
						this.data.push(...modifiedResponse);
						// this.isLoadingData = false;

					}
				}).catch((err) => {
					this.isLoadingData = false;
				}).finally(() => {
					this.isLoadingData = false;
				})

		},
		mostrarDrawer() {
			this.drawerVisivel = true;
		},
		fecharDrawer() {
			this.drawerVisivel = false;
		},
		// Event listener for input change on table search field.
		onSearchChange() {
			if (this.query.length > 0) {
				this.data = data.filter((row) => {
					for (const key in row) {
						if (
							row[key]
								.toString()
								.toLowerCase()
								.includes(this.query.trim().toLowerCase())
						)
							return true;
					}
					return false;
				});
			} else {
				this.data = data;
			}
		},
		// Event listener for table row selection change.
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},
		// Export table in CSV format.
		csvExport(arrData) {
			let csvContent = 'data:text/csv;charset=utf-8,';
			csvContent += [
				Object.keys(arrData[0]).join('|'),
				...arrData.map((item) => Object.values(item).join('|')),
			]
				.join('\n')
				.replace(/(^\[)|(\]$)/gm, '');

			const data = encodeURI(csvContent);
			const link = document.createElement('a');
			link.setAttribute('href', data);
			link.setAttribute('download', 'muse-dashboard-csv.csv');
			link.click();
		},
	},
};
</script>
  
<style lang="scss" scoped>
.table-avatar-info {
	display: flex;
	align-items: center;
}

.table-avatar-info .ant-avatar {
	margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
	font-size: 12px;
}

.btn-Status::v-deep .anticon {
	line-height: 0;
}</style>
  